import axios from 'axios'
import { Message } from 'element-ui'
const request = axios.create({
  baseURL: '/api',
})
request.interceptors.response.use(
  (response) => {
    const res = response
    if (res.data.code != 200) {
      Message({
        message: `${res.data.msg}` || '请求错误',
        type: 'error',
        duration: 2 * 1000,
      })
    }
    return res
  },
  (error) => {
    // Message.error(error.response.msg || error.response.status);
    //这里还可以根据status值（401、500等）分别做对应的处理，比如记录当前请求url，登录以后自动跳转到原url
    return Promise.reject(error)
  }
)
export default request
