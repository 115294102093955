<template>
  <!-- star -->

  <div class="homepage_wrap">
    <!-- banner图和导航栏 -->
    <div class="banner_nav">
      <!-- banner -->
      <div class="banner_nav_bg">
        <img src="../assets/img/bg1.jpg" alt="" />
      </div>
      <!-- 导航栏 -->
      <div class="navWrap">
        <div class="navWrap_center">
          <div class="nav_title">
            <!-- <img src="../assets/img/logo.png" alt="" /> -->
            <div style="font-size: 25px; color: #003662; font-weight: 700">
              海泰克思
            </div>
            <div style="color: #969393; text-align: center; font-weight: 500">
              haitaikes
            </div>
          </div>
          <div class="right_box">
            <div class="dropdown">
              <button class="dropbtn">产品中心</button>
              <div class="dropdown-content">
                <router-link to="/zqt">"惠企通"企业服务平台</router-link>
                <router-link to="/wgsp">无感审批动态监管平台</router-link>
                <router-link to="/jxkh">绩效考核管理系统</router-link>
                <router-link to="/zdxm">重点项目全过程监管平台</router-link>
                <router-link to="/xsd">新时代文明实践云平台</router-link>
              </div>
            </div>

            <ul class="navWrap_center_ul">
              <li
                v-for="(item, index) in navList"
                :key="index"
                @click="funyuming(item.url)"
              >
                {{ item.title }}
              </li>
            </ul>
           
          </div>
        </div>
      </div>
      <!-- title -->
      <div class="banner_title_wrap">
        <div class="banner_title_center">
          <!-- <p>{{ title }}</p> -->
          <!-- <div>科技创新引领产业发展 <span>·</span>产教融合共育技术人才</div> -->
        </div>
      </div>
    </div>

    <div class="bottom_body">
      <!-- 产品服务 -->
      <div class="service">
        <div class="bottom_title">产品服务</div>
        <div class="service_describe">
          多场景协同办公,利用平台技术创新能力,打造高绩效办公单位
        </div>
        <div class="service_item_box">
          <div class="service_item">
            <div class="service_item_title">绩效考核管理系统</div>
            <img src="@/assets/img/shouye/jixiaokaohe.jpg" alt="" />
            <div class="service_item_describe">自定义考核项、考核规则</div>
            <div class="service_item_describe">实现无纸化绩效考核</div>
            <el-button type="primary" @click="toModel('jxkh')"
              >了解详情</el-button
            >
          </div>
          <div class="service_item">
            <div class="service_item_title">重点项目监管平台</div>
            <img src="@/assets/img/shouye/zhongdianxiangmu.jpg" alt="" />
            <div class="service_item_describe">项目全过程监管</div>
            <div class="service_item_describe">强化经济运行监测分析</div>
            <el-button type="primary" @click="toModel('zdxm')"
              >了解详情</el-button
            >
          </div>
          <div class="service_item">
            <div class="service_item_title">无感审批动态监管平台</div>
            <img src="@/assets/img/shouye/wuganshenpi.jpg" alt="" />
            <div class="service_item_describe">一套考核办法实现动态监管</div>
            <div class="service_item_describe">考核结果实现无感审批</div>
            <el-button type="primary" @click="toModel('wgsp')"
              >了解详情</el-button
            >
          </div>
          <div class="service_item">
            <div class="service_item_title">新时代文明实践平台</div>
            <img src="@/assets/img/shouye/xinshidai.jpg" alt="" />
            <div class="service_item_describe">整合志愿服务与志愿者资源</div>
            <div class="service_item_describe">实现各实践所、站的统一管理</div>
            <el-button type="primary" @click="toModel('xsd')"
              >了解详情</el-button
            >
          </div>
          <div class="service_item">
            <div class="service_item_title">惠企通企业服务平台</div>
            <img src="@/assets/img/shouye/huiqitong.jpg" alt="" />
            <div class="service_item_describe">综合十大功能聚焦企业服务</div>
            <div class="service_item_describe">服务助企，赋码护企</div>
            <el-button type="primary" @click="toModel('zqt')"
              >了解详情</el-button
            >
          </div>
        </div>
      </div>
      <!-- 覆盖行业、售后服务&在线服务 -->
      <div class="industry_afterSales">
        <div class="industry">
          <div class="bottom_title">覆盖行业</div>
          <div class="industry_box">
            <div class="industry_item">
              <div class="industry_item_title">教育行业</div>
              <img src="@/assets/img/shouye/jiaoyu.jpg" alt="" />
            </div>
            <div class="industry_item">
              <div class="industry_item_title">政务服务</div>
              <img src="@/assets/img/shouye/zhengwu.jpg" alt="" />
            </div>
            <div class="industry_item">
              <div class="industry_item_title">智慧交通</div>
              <img src="@/assets/img/shouye/jiaotong.jpg" alt="" />
            </div>
            <div class="industry_item">
              <div class="industry_item_title">智慧农业</div>
              <img src="@/assets/img/shouye/nongye.jpg" alt="" />
            </div>
          </div>
          <div class="programme">
            <div class="programme_title">
              <div class="shuxian"></div>
              <div class="title_text">智慧政务解决方案</div>
            </div>
            <div class="programme_body">
              <div class="programme_body_left">
                <div class="programme_info">
                  覆盖政府多层级组织管理、行政审批、消息预警、绩效考核、服务评价等政务办公与事务处理应用。
                </div>
                <div class="programme_title">
                  <div class="shuxian"></div>
                  <div class="title_text">推荐应用</div>
                </div>
                <div class="recommend">
                  <div class="recommend_item">数据化管理</div>
                  <div class="recommend_item">政务审批</div>
                  <div class="recommend_item">组织管理</div>
                  <div class="recommend_item">可视化数据分析</div>
                  <div class="recommend_item">考核体系</div>
                </div>
              </div>
              <div class="programme_body_right">
                <div class="beRelatedTo">
                  <div class="programme_title">
                    <div class="shuxian"></div>
                    <div class="title_text">相关产品</div>
                  </div>
                </div>
                <div class="beRelatedTo_item">
                  <div class="beRelatedTo_item_title">无感审批动态监管平台</div>
                  <div class="beRelatedTo_item_info">
                    一套考核办法实现动态监管
                  </div>
                  <div class="beRelatedTo_item_info">考核结果实现无感审批</div>
                  <a href="#box" class="experience">体验产品</a>
                </div>
                <div class="beRelatedTo_item">
                  <div class="beRelatedTo_item_title">绩效考核管理系统</div>
                  <div class="beRelatedTo_item_info">
                    自定义考核项、考核规则
                  </div>
                  <div class="beRelatedTo_item_info">实现无纸化绩效考核</div>
                  <a href="#box" class="experience">体验产品</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="afterSales">
          <div class="bottom_title">在线服务*售后服务</div>
          <div class="afterSales_body">
            <div class="afterSales_img">
              <img src="@/assets/img/shouye/shouhou.jpg" alt="" />
            </div>
            <div class="afterSales_text">
              <div style="margin-bottom: 30px">
                为您提供便捷的服务通道，高效、全天候的服务受理，全面的服务保障及多样化的客户成功支持计划。并借助专家为您在每一步提供的帮助，加速实现您的业务价值
              </div>
              <div style="margin-bottom: 30px">
                为多个行业提供数字化解决方案，安全稳定，引领智变
              </div>
              <div style="font-weight: 600">全年维护 不间断服务保障</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 资讯中心 -->
      <div class="information">
        <div class="bottom_title">资讯中心</div>
        <div class="information_box">
          <div class="information_item">
            <img src="@/assets/img/shouye/zixun.jpg" alt="" />
            <div class="information_text">
              河南社旗:惠企政策计算器 一键直达......
            </div>
          </div>
          <div class="information_item">
            <img src="@/assets/img/shouye/zixun.jpg" alt="" />
            <div class="information_text">
              河南社旗:惠企政策计算器 一键直达......
            </div>
          </div>
          <div class="information_item">
            <img src="@/assets/img/shouye/zixun.jpg" alt="" />
            <div class="information_text">
              河南社旗:惠企政策计算器 一键直达......
            </div>
          </div>
        </div>
        <div class="information_btn" @click="toMore">
          查看更多新闻资讯
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- 联系 -->
      <div class="contact" id="box">
        <div class="contact_text">
          <div class="contact_title">为您量身定制解决方案</div>
          <div>
            欢迎体验海泰克思产品，如果您需要体验产品，请将您的联系方式填写右侧，点击预约试用，稍后会有专员负责给您开通。
          </div>
        </div>
        <div class="contact_form">
          <div class="contact_title">联系方式</div>
          <el-form
            ref="form"
            :model="form"
            label-width="100px"
            :rules="rules"
            :hide-required-asterisk="true"
          >
            <el-form-item label="姓名:" prop="name">
              <el-input v-model="form.name" placeholder="请填写姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系方式:" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请填写联系方式"
              ></el-input>
            </el-form-item>
            <el-form-item label="试用产品:" prop="product">
              <el-select v-model="form.product" placeholder="请选择试用产品">
                <el-option
                  style="padding: 0 20px"
                  label='"惠企通"企业服务平台'
                  value='"惠企通"企业服务平台'
                ></el-option>
                <el-option
                  style="padding: 0 20px"
                  label="无感审批动态监管平台"
                  value="无感审批动态监管平台"
                ></el-option>
                <el-option
                  style="padding: 0 20px"
                  label="绩效考核管理系统"
                  value="绩效考核管理系统"
                ></el-option>
                <el-option
                  style="padding: 0 20px"
                  label="重点项目全过程监管平台"
                  value="重点项目全过程监管平台"
                ></el-option>
                <el-option
                  style="padding: 0 20px"
                  label="新时代文明实践云平台"
                  value="新时代文明实践云平台"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="contact_btn">
              <el-button @click="submitClick">预约试用</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import { purpose } from '@/api'
import footer2 from '../components/footer.vue'
export default {
  name: 'index',
  components: {
    footer2,
  },
  data() {
    return {
      navList: [
        { title: '首页', url: '/' },
        { title: '产品中心', url: '/jituangaikuo' },
        { title: '资讯中心', url: '/advisoryCommittee' },
        { title: '关于我们', url: '/about' },
        { title: '联系我们', url: '/contact' },
        // { title: "联系我们", url: "/lianxi" },
        // { title: "招聘信息", url: "/zhaopinxinxi0" },
      ],
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        product: [
          { required: true, message: '请选择使用产品', trigger: 'blur' },
        ],
      },
      form: {
        name: '',
        phone: '',
        product: '',
      },
    }
  },
  computed: {
    title() {
      return this.$store.state.title
    },
  },
  methods: {
    submitClick() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const res = await purpose(this.form)
          if (res.data.code == 200) {
            this.$message({
              message: '提交成功',
              type: 'success',
            })
            this.$refs.form.resetFields()
          }
        } else {
          return false
        }
      })
    },
    toModel(e) {
      this.$router.push({
        path: `/${e}`,
      })
    },

    toMore() {
      this.$router.push({
        path: `/advisoryCommittee`,
      })
    },
    funyuming(msg) {
      this.$router.push(msg)
    },
  },
}
</script>
<style scoped lang="scss">
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.homepage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}

.banner_nav {
  width: 100vw;
  height: 500px;
  position: relative;
}
.banner_nav_bg {
  height: 100%;
}
.banner_nav_bg img {
  width: 100%;
  height: 100%;
  /* 文本居顶部对齐 */
  vertical-align: top;
}

/* 导航部分 */

.navWrap {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*  */
  padding-top: 40px;
}

.navWrap_center {
  width: 90%;
  height: 80px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  /*  */
  display: flex;
  justify-content: space-between;
}

.nav_title {
  /* width: 181px; */
  height: 54px;
  margin: 13px 0 0 60px;
}

.navWrap_center_ul {
  /* border:1px solid red; */
  margin-right: 121px;
}

.navWrap_center_ul li {
  display: inline-block;
  height: 77px;
  line-height: 77px;
  width: 70px;
  /* border-bottom: 4px solid blue; */
  margin-left: 50px;
  padding: 0 5px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  /* line-height: 22px; */
}
.navWrap_center_ul li:hover {
  cursor: pointer;
  color: rgba(35, 51, 127, 1);
  border-bottom: 2px solid rgba(35, 51, 127, 1);
}

/* title */

.banner_title_wrap {
  width: 100%;
  position: absolute;
  bottom: 290px;
  /* border: 1px solid yellow; */
}

.banner_title_center {
  /* border: 1px solid white; */
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  font-size: 90px;
  font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
  font-weight: bold;
  color: #ffffff;
  line-height: 124px;
  letter-spacing: 1px;
}

.banner_title_center div {
  padding-top: 14px;
  font-size: 20px;
  font-family: Helvetica;
  line-height: 24px;
  letter-spacing: 4px;
}

.banner_title_center div span {
  margin: 0 18px;
}
.dropbtn {
  background-color: #fff;
  color: rgb(12, 11, 11);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.right_box {
  position: relative;
}
.dropdown {
  top: 11px;
  // left: 388px;
  left: 160px;
  position: absolute;
  display: inline-block;
}

.dropdown-content {
  text-align: center;
  display: none;
  position: absolute;
  left: -50%;
  background-color: #f9f9f9;
  min-width: 230px;
  min-height: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: rgb(6, 6, 6);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.bottom_body {
  background-color: #f7fafd;

  box-sizing: border-box;
  .bottom_title {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  // 产品服务
  .service {
    padding: 30px 80px 20px;
    box-sizing: border-box;

    .service_describe {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      margin: 15px 0 20px;
    }
    .service_item_box {
      display: flex;
      justify-content: space-between;
      .service_item {
        background: #fff;
        padding: 50px 20px;
        // width: 263px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        .service_item_title {
          color: #333333;
          font-size: 20px;
        }
        img {
          width: 150px;
          height: 150px;
          margin: 30px auto;
        }
        .service_item_describe {
          color: #797979;
          font-size: 18px;
        }
        ::v-deep .el-button {
          width: 150px;
          background: #0080fb;
          margin-top: 20px;
        }
      }
    }
  }
  .industry_afterSales {
    background-color: #fff;
    padding: 30px 80px 40px;
    box-sizing: border-box;

    .industry {
      .industry_title {
        text-align: center;
        font-size: 30px;
        margin-bottom: 30px;
        font-weight: 700;
      }
      .industry_box {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 50px;
        .industry_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .industry_item_title {
            font-size: 20px;
            margin-bottom: 30px;
          }
          img {
            width: 130px;
            height: 130px;
          }
        }
      }
      .programme {
        .programme_title {
          font-size: 25px;
          font-weight: 700;
          display: flex;
          align-items: center;
          .shuxian {
            width: 8px;
            height: 40px;
            background-color: #0070f7;
            margin-right: 15px;
          }
        }
        .programme_body {
          margin-top: 50px;
          margin-left: 25px;
          display: flex;
          .programme_body_left {
            flex: 1;
            .programme_info {
              color: #797979;
              font-size: 20px;
              margin-bottom: 50px;
              margin-left: 8px;
            }
            .recommend {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              margin-top: 20px;
              .recommend_item {
                padding: 0 50px;
                line-height: 50px;
                box-shadow: 5px 5px 5px 0 #ccc;
                margin-bottom: 50px;
                margin-right: 30px;
              }
            }
          }
          .programme_body_right {
            width: 300px;
            margin-left: 100px;
            .beRelatedTo {
              border-bottom: 1px solid #ccc;
              padding: 0 0 30px 20px;
              box-sizing: border-box;
              margin-bottom: 25px;
            }
            .beRelatedTo_item {
              position: relative;
              line-height: 50px;
              // height: 150px;
              // overflow: hidden;
              .beRelatedTo_item_title {
                font-size: 20px;
                font-weight: 600;
              }
              .beRelatedTo_item_info {
                color: #797979;
                font-size: 18px;
              }
              .experience {
                display: block;
                width: 100%;
                font-size: 18px;
                color: #0070f7;
                border-bottom: 1px solid #0070f7;
                opacity: 0;
                position: absolute;
                top: 100px;
                left: 0;
              }
              .experience:hover {
                cursor: pointer;
              }
            }
            .beRelatedTo_item:hover {
              height: 200px;
            }
            .beRelatedTo_item:hover .experience {
              // height: 220px;
              position: absolute;
              top: 140px;
              left: 0;
              opacity: 1;
              transition: all 1s;
            }
          }
        }
      }
    }
    .afterSales {
      margin-top: 50px;
      .afterSales_title {
        text-align: center;
        font-size: 30px;
        margin-bottom: 30px;
        font-weight: 700;
      }
      .afterSales_body {
        background-color: #ecf7fb;
        border-radius: 20px;
        padding: 50px 50px 80px 80px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .afterSales_img {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 250px;
            height: 250px;
          }
        }
        .afterSales_text {
          margin-left: 100px;
          font-size: 20px;
          color: #797979;
          line-height: 50px;
        }
      }
    }
  }
  .information {
    padding: 40px 80px 40px;
    box-sizing: border-box;
    .information_box {
      margin: 30px 0 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .information_item {
        img {
          width: 350px;
          height: 200px;
        }
        .information_text {
          margin-top: 20px;
          font-size: 20px;
        }
      }
    }
    .information_btn {
      margin: 0 auto;
      width: 200px;

      height: 60px;
      background: linear-gradient(90deg, #007cfa 0%, #23c9fb 100%);
      color: #fff;
      line-height: 60px;
      text-align: center;
      .el-icon-arrow-right {
        font-weight: 700;
      }
    }
    .information_btn:hover {
      cursor: pointer;
    }
  }
  .contact {
    background-image: url('~@/assets/img/shouye/lianxi.jpg');
    background-size: cover;
    background-color: #003662;
    // height: 500px;
    display: flex;
    justify-content: space-between;
    padding: 120px 150px 20px 100px;
    .contact_text {
      font-size: 20px;
      line-height: 50px;
      color: #d3d2d2;
      width: 40%;
      .contact_title {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 30px;
        text-align: center;
      }
    }
    .contact_form {
      margin-left: 200px;
      flex: 1;
      .contact_title {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 15px;
        text-align: center;
      }
      ::v-deep .el-form-item__label {
        text-align: right;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
      }
      ::v-deep .el-form-item {
        margin-bottom: 20px;
      }
      ::v-deep .el-form-item__content {
        margin-left: 150px !important;
      }
      ::v-deep .el-input__inner {
        width: 250px;
      }
      .contact_btn {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 50px;
        ::v-deep .el-button {
          width: 150px;
          color: #0051fb;
        }
      }
    }
  }
}
</style>
