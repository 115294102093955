import { render, staticRenderFns } from "./xsd.vue?vue&type=template&id=6efa6472&scoped=true&"
import script from "./xsd.vue?vue&type=script&lang=js&"
export * from "./xsd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6efa6472",
  null
  
)

export default component.exports