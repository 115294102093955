<template>
  <div>
    <div class="wrap">
      <div class="logo">
        <!-- <img :src="logoimg" /> -->
        <div
          class="logo_text"
          style="font-size: 25px; color: rgb(0, 54, 98); font-weight: 700"
        >
          海泰克思
        </div>
        <div
          style="
            color: rgb(150, 147, 147);
            text-align: center;
            font-weight: 500;
          "
        >
          haitaikes
        </div>
      </div>

      <div class="nav">
        <div class="nav_box">
          <div class="nav_item" @click="funyuming('/')">首页</div>
          <div class="nav_item">
            <el-dropdown>
              <span class="el-dropdown-link"> 产品中心 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="dropdown-content">
                  <router-link to="/zqt">"惠企通"企业服务平台</router-link>
                </el-dropdown-item>
                <el-dropdown-item class="dropdown-content">
                  <router-link to="/wgsp">无感审批动态监管平台</router-link>
                </el-dropdown-item >
                <el-dropdown-item class="dropdown-content"
                  ><router-link to="/jxkh"
                    >绩效考核管理系统</router-link
                  ></el-dropdown-item
                >
                <el-dropdown-item class="dropdown-content">
                  <router-link to="/zdxm"
                    >重点项目全过程监管平台</router-link
                  ></el-dropdown-item
                >
                <el-dropdown-item class="dropdown-content">
                  <router-link to="/xsd"
                    >新时代文明实践云平台</router-link
                  ></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="nav_item" @click="funyuming('/advisoryCommittee')">
            资讯中心
          </div>
          <div class="nav_item" @click="funyuming('/about')">关于我们</div>
          <div class="nav_item" @click="funyuming('/contact')">联系我们</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
      navList: [
        { title: '首页', url: '/' },
        { title: '产品中心', url: '/jituangaikuo' },
        { title: '资讯中心', url: '/advisoryCommittee' },
        { title: '关于我们', url: '/about' },
        { title: '联系我们', url: '/contact' },
      ],
    }
  },
  methods: {
    funyuming(msg) {
      this.$router.push(msg)
    },
    funRouter() {
      this.$router.push('/zqt')
    },
  },
}
</script>
<style scoped>
.wrap {
  position: relative;
  width: 100vw;
  height: 101px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
}
.logo {
  position: absolute;
  left: 5%;
  top: 23px;
}

/* .nav ul {
  height: 100%;
  display: flex;
  align-items: center;
} */





.dropdown-content {
  text-align: center;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  /* display: block; */
}

.nav {
  width: 556px;
  position: absolute;
  left: 1000px;
  top: 50%;
}
.nav_box {
  display: flex;
  align-items: center;
}
.nav_box .nav_item {
  /* list-style-type: none; */
  color: rgba(68, 68, 68, 1);
  font-size: 16px;
  margin-right: 20px;
  cursor: pointer;
}
.nav_box .nav_item:hover {
  color: rgba(35, 51, 127, 1);
  border-bottom: 2px solid rgba(35, 51, 127, 1);
}
.el-dropdown-link {
    cursor: pointer;
    color: rgba(68, 68, 68, 1);
    font-size:16px;
  }
  .el-dropdown-menu{
    left:950px !important;
  }
  .el-dropdown-item {
    padding-left: 100px;
}
</style>
