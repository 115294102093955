import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import zqt from '../views/zqt.vue'
import jxkh from '../views/jxkh.vue'
import xsd from '../views/xsd.vue'
import wgsp from '../views/wgsp.vue'
import zdxm from '../views/zdxm.vue'
import about from '../views/about.vue'
import advisoryCommittee from '../views/advisoryCommittee.vue'
import casePage from '../views/casePage.vue'
import information from '../views/information.vue'
import contact from '../views/contact.vue'

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: {
      title: '首页',
    },
  },
  {
    path: '/zqt',
    name: 'zqt',
    component: zqt,
    meta: {
      title: '惠企通',
    },
  },
  {
    path: '/jxkh',
    name: 'jxkh',
    component: jxkh,
    meta: {
      title: '绩效考核',
    },
  },
  {
    path: '/xsd',
    name: 'xsd',
    component: xsd,
    meta: {
      title: '新时代文明',
    },
  },
  {
    path: '/wgsp',
    name: 'wgsp',
    component: wgsp,
    meta: {
      title: '无感审批',
    },
  },
  {
    path: '/zdxm',
    name: 'zdxm',
    component: zdxm,
    meta: {
      title: '重点项目',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: about,
    meta: {
      title: '关于我们',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
    meta: {
      title: '联系我们',
    },
  },
  {
    path: '/advisoryCommittee',
    name: 'advisoryCommittee',
    component: advisoryCommittee,
    meta: {
      title: '资讯中心',
    },
  },
  {
    path: '/casePage',
    name: 'casePage',
    component: casePage,
    meta: {
      title: '客户案例',
    },
  },
  {
    path: '/information',
    name: 'information',
    component: information,
    meta: {
      title: '资讯中心',
    },
  },
]
// title

const router = new VueRouter({
  routes,
  // 跳转页面后 回到顶部
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})

export default router

//title
const defaultTitle = '海泰克斯'
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `海泰克斯-${to.meta.title}` : defaultTitle
  next()
})
