<template>
  <div class="">
    <nav2></nav2>
    <img src="@/assets/img/model/jxkh.jpg" alt="" style="width:100%;" />
    <footer2></footer2>
  </div>
</template>

<script>
import nav2 from '@/components/nav.vue'
import footer2 from '../components/footer.vue'
export default {
  components: {
    footer2,
    nav2
  },
  data() {
    return {}
  },
  methods: {},
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss"></style>
