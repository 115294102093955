<template>
  <div>
    <!-- ---------- -->

    <div class="footer_warp">
      <div class="footer_cont">
        <!-- 上部 -->
        <div class="footer_cont_l">
          <!--上部 左 -->
          <div class="footer_cont_l_div">
            <div class="footer_cont_l_div_item">
              <p class="footer_cont_l_title">客户案例</p>
              <ul class="footer_cont_l_ul">
                <li
                  v-for="(item, index) in arrList"
                  :key="index"
                  @click="toGuid(item.url, item.id)"
                >
                  {{ item.title }}
                </li>

                <!-- <li>企业荣誉</li> -->
              </ul>
            </div>
            <div class="footer_cont_l_div_item">
              <p class="footer_cont_l_title">行业解决方案</p>
              <ul class="footer_cont_l_ul">
                <li
                  v-for="(item, index) in arrList2"
                  :key="index"
                  @click="toPages(item.url)"
                >
                  {{ item.title }}
                </li>
              </ul>
            </div>
            <div class="footer_cont_l_div_item">
              <p class="footer_cont_l_title">新闻中心</p>
              <ul class="footer_cont_l_ul">
                <li
                  v-for="(item, index) in arrList3"
                  :key="index"
                  @click="toNews(item.url)"
                >
                  {{ item.title }}
                </li>
              </ul>
            </div>
            <div class="footer_cont_l_div_item2">
              <p class="footer_cont_l_title">服务与支持</p>
              <ul class="footer_cont_l_ul">
                <li
                  v-for="(item, index) in arrList4"
                  :key="index"
                  @click="toNews(item.url)"
                >
                  {{ item.title }}
                </li>
                <!-- <router-link to="zhaopinxinxi0"><li>招聘信息</li></router-link> -->
              </ul>
            </div>
          </div>
        </div>
        <!-- top 中 -->
        <div class="footer_cont_c">
          <div class="footer_cont_l_div_item">
            <p class="footer_cont_l_title">联系我们</p>
            <ul class="footer_cont_l_ul">
              <!-- <li
									v-for="(item, index) in arrList4"
									:key="index"
									@click="toNews(item.url)"
								>
									{{ item.title }}
								</li> -->
              <li>
                <img src="@/assets/img/shouye/dianhua.png" alt="" />
                <span>4001001318</span>
              </li>
              <li>
                <img src="@/assets/img/shouye/youxiang.png" alt="" />
                <span>15308903966@163.com</span>
              </li>
              <li>
                <img src="@/assets/img/shouye/dizhi.png" alt="" />
                <span style="color: #949191; font-weight: 400; font-size: 16px"
                  >海南省文昌市清澜高隆湾旅游大道中南森海湾B4单元403号房</span
                >
              </li>
              <li>
                <img
                  src="@/assets/img/shouye/beian.png"
                  alt=""
                  style="width: 26px; height: 28px"
                />
                <span style="color: #949191; font-weight: 400; font-size: 16px">
                  <a
                    style="color: #949191"
                    href="https://beian.miit.gov.cn/#/Integrated/index"
                    target="_blank"
                    >琼ICP备2023011948号-1</a
                  >
                </span>
              </li>
            </ul>
          </div>
        </div>
        <!-- top 右 -->
        <div class="footer_cont_r">
          <div>
            <div class="footer_cont_r_border">
              <img src="../assets/img/shouye/erweima.jpg" alt="" />
            </div>
            <p>关注微信公众号</p>
          </div>
        </div>
      </div>
    </div>

    <!-- --------- -->
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      arrList: [
        {
          title: '惠企通企业服务平台',
          url: '/zqt',
          id: 'about',
        },
        {
          title: '无感审批动态监管平台',
          url: '/wgsp',
          id: 'CultureInfo',
        },
        {
          title: '绩效考核管理系统',
          url: '/jxkh',
          id: 'history',
        },
        {
          title: '重点项目全过程监管平台',
          url: '/zdxm',
          id: 'history',
        },
        {
          title: '新时代文明实践云平台',
          url: '/xsd',
          id: 'history',
        },
      ],
      arrList2: [
        {
          title: '教育行业',
          url: '/#',
        },
        {
          title: '政务服务',
          url: '/#',
        },
        {
          title: '重点项目',
          url: '/#',
        },
        {
          title: '农业行业',
          url: '/#',
        },
      ],
      arrList3: [
        {
          title: '公司新闻',
          url: '/#',
        },
        {
          title: '行业动态',
          url: '/#',
        },
      ],
      arrList4: [
        {
          title: '软件试用',
          url: '/#',
        },
        {
          title: '常见问题',
          url: '/#',
        },
      ],
    }
  },

  mounted() {
    let _this = this
    _this.$nextTick(function () {
      window.addEventListener('scroll', _this.handleScroll)
    })
  },

  methods: {
    // 锚点跳转
    toGuid(path, id) {
      localStorage.setItem('toId', id)
      this.$router.push(path)
      //查找存储的锚点id
      let Id = localStorage.getItem('toId')
      let toElement = document.getElementById(Id)
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView()
        localStorage.setItem('toId', '')
      }
    },

    toPages(url) {
      this.$router.push(url)
    },
    toNews(url) {
      this.$router.push(url)
    },
  },
}
</script>
<style scoped lang="scss">
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.footer_warp {
  width: 100vw;
  height: 388px;
  background: #f7fafd;
}
.footer_cont {
  width: 90%;
  margin: 0 auto;
  display: flex;
}
.footer_cont_l {
  width: 80%;
  font-size: 16px;
  font-weight: 500;
  color: #dddddd;
  line-height: 22px;
  padding-top: 77px;
}
.footer_cont_l_title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: black;
  line-height: 22px;
  width: 100px;
}
.footer_cont_l_ul {
  margin-top: 36px;
}
.footer_cont_l_ul li {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
  }
  span {
    margin-left: 20px;
    font-weight: 600;
    color: black;
    font-size: 20px;
  }
}

.footer_cont_l_div {
  display: flex;
}
.footer_cont_l_div_item {
  margin-right: 40px;
}
.footer_cont_c {
  margin-top: 77px;
  margin-left: 30px;
  .footer_cont_l_div_item {
    margin-right: 30px;
  }
}
.address {
  width: 467px;
  font-size: 16px;
  font-family: 'PingFangSC-Regular', 'PingFang SC';
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  margin-top: 42px;
}
.footer_cont_r {
  margin-top: 79px;
  margin-left: 30px;
}
.footer_cont_r p {
  text-align: center;
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 17px;
}
.footer_cont_r_border img {
  width: 200px;
  height: 200px;
  margin: 7px 8px;
}
</style>
