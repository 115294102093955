<!-- star -->
<template>
  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：<span>联系我们</span></div>
    </div>
    <div class="text">如果有任何问题请联系我们，我们7*24小时竭诚为您服务</div>
    <div class="phone">4001001318</div>

    <div class="center">
      <div class="map_img">
        <img src="@/assets/img/model/ditu.jpg" alt="" />
      </div>
      <div class="right_info">
        <div class="title">海泰克思</div>
        <div class="company_info_item">
          <img src="@/assets/img/model/dianhua.jpg" alt="" />
          <div class="right">
            <span style="font-weight: 500">电话：</span>
            <span style="color: #797979">4001001318</span>
          </div>
        </div>
        <div class="company_info_item">
          <img src="@/assets/img/model/youxiang.jpg" alt="" />
          <div class="right">
            <span style="font-weight: 500">邮箱：</span>
            <span style="color: #797979">15308903966@163.com</span>
          </div>
        </div>
        <div class="company_info_item">
          <img src="@/assets/img/model/dizhi.jpg" alt="" />
          <div class="right">
            <span style="font-weight: 500">地址：</span>
            <span style="color: #797979"
              >海南省文昌市清澜高隆湾旅游大道中南森海湾B4单元403号房</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 在线咨询 -->
    <div class="advisory_committee">
      <div class="advisory_committee_title">在线咨询</div>
      <el-form ref="form" :model="form" label-width="0px" :rules="rules">
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <div class="form_label">姓名</div>
            <el-form-item label="" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <div class="form_label">手机号</div>
            <el-form-item label="" prop="phone">
              <el-input v-model="form.phone" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <div class="form_label">公司</div>
            <el-form-item label="">
              <el-input v-model="form.company"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <div class="form_label">您的职位</div>
            <el-form-item label="">
              <el-input v-model="form.position"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="24">
            <div class="form_label">咨询内容</div>
            <el-form-item label="">
              <el-input
                v-model="form.content"
                type="textarea"
                :rows="10"
                maxlength="500"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form_btn">
          <el-button @click="submitClick">立即提交</el-button>
        </div>
      </el-form>
    </div>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import { consult } from '@/api/index'

import footer2 from '@/components/footer.vue'
import banner2 from '@/components/banner.vue'
import nav2 from '@/components/nav.vue'
export default {
  name: 'zqt',
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: '联系我们',
      Img1: require('@/assets/img/model/about.jpg'),
      activeName: 'first',
      form: {
        company: '',
        content: '',
        name: '',
        phone: '',
        position: '',
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
      },
    }
  },
  methods: {
    submitClick() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const res = await consult(this.form)
          if (res.data.code == 200) {
            this.$message({
              message: '提交成功',
              type: 'success',
            })
            this.$refs.form.resetFields()
          }
        } else {
          return false
        }
      })
    },
  },
  created() {},
}
</script>
<style scoped lang="scss">
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}

/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}
.text {
  font-size: 18px;
  color: #949191;
  line-height: 100px;
  text-align: center;
}
.phone {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.center {
  padding: 50px 80px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 600px;
  .map_img {
    width: 40%;
    height: 100%;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .right_info {
    flex: 1;
    height: 100%;
    background-color: #f6f8fa;
    padding: 20px 15px 30px 50px;
    box-sizing: border-box;
    .title {
      font-size: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid #ccc;
      color: #333333;
    }
    .company_info_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 50px 0;
      img {
        width: 25px;
        height: 25px;
      }
      .right {
        margin-left: 15px;
        font-size: 20px;
      }
    }
  }
}
.advisory_committee {
  padding: 0px 150px 30px;
  box-sizing: border-box;
  .advisory_committee_title {
    text-align: center;
    font-size: 25px;
  }
  .form_label {
    font-size: 20px;
    line-height: 40px;
  }
  ::v-deep .el-row {
    margin-bottom: 60px;
  }
  ::v-deep .el-input__inner {
    height: 50px;
    line-height: 50px;
    border: none;
    background-color: #f0f4f9;
    color: black;
  }
  ::v-deep .el-textarea__inner {
    border: none;
    background-color: #f0f4f9;
    color: black;
  }
  .form_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-button {
      width: 300px;
      height: 50px;
      background: linear-gradient(90deg, #007cfa 0%, #23c9fb 100%);
      color: #fff;
      text-align: center;
      border-radius: 0;
      border: none;
    }
  }
}
</style>
