<!-- star -->
<template>
  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：<span>资讯中心</span></div>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="客户案例" name="first">
        <div class="bottom_body">
          <el-row
            type="flex"
            justify="flex-start"
            :gutter="100"
            v-if="dataList.length > 0"
          >
            <el-col :span="8" v-for="item in dataList" :key="item.id">
              <div class="case_item" @click="itemClick(item.id)">
                <img :src="item.cover" alt="" />
                <div class="bottom_text">{{ item.title }}</div>
                <div class="date">{{ item.publishDate }}</div>
              </div>
            </el-col>
          </el-row>
          <el-empty description="暂无数据" v-else></el-empty>
        </div>
      </el-tab-pane>
      <el-tab-pane label="资讯动态" name="second">
        <div class="bottom_body">
          <el-row
            type="flex"
            justify="flex-start"
            :gutter="100"
            v-if="dataList.length > 0"
          >
            <el-col :span="8" v-for="item in dataList" :key="item.id">
              <div class="case_item" @click="itemClick(item.id)">
                <img :src="item.cover" alt="" />
                <div class="bottom_text">{{ item.title }}</div>
                <div class="date">{{ item.publishDate }}</div>
              </div>
            </el-col>
          </el-row>
          <el-empty description="暂无数据" v-else></el-empty>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- <el-pagination layout="prev, pager, next" :total="50"> </el-pagination> -->
    <el-pagination
      background
      layout="total,prev, pager, next"
      :total="total"
      :page-size="6"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import { infoList } from '@/api'

import footer2 from '@/components/footer.vue'
import banner2 from '@/components/banner.vue'
import nav2 from '@/components/nav.vue'
export default {
  name: 'zqt',
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: '资讯中心',
      Img1: require('@/assets/img/model/about.jpg'),
      activeName: 'first',
      dataList: [],
      total: 0,
      form: {
        pageSize: 6,
        pageNum: 1,
        type: 1,
      },
    }
  },
  methods: {
    async handleCurrentChange(e) {
      this.form.pageNum = e
      await this.infoList()
    },
    async infoList() {
      const res = await infoList(this.form)
      if (res.data.code == 200) {
        this.dataList = res.data.data.rows
        this.total = res.data.data.total
        console.log(this.total)
      }
    },
    itemClick(id) {
      this.$router.push({
        path: '/casePage',
        query: {
          id: id,
        },
      })
    },
    toInformation() {
      this.$router.push({
        path: '/information',
      })
    },
    async handleClick(e) {
      if (e.name == 'second') {
        this.form.type = 2
      } else {
        this.form.type = 1
      }
      await this.infoList()
    },
  },
  async created() {
    await this.infoList()
  },
}
</script>
<style scoped lang="scss">
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}

/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}

::v-deep .el-tabs__nav-wrap {
  background-color: #fff;
  // height:100px;
}
::v-deep .el-tabs__nav-scroll {
  height: 100px;
  line-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-tabs__active-bar {
  width: 40%;
}
::v-deep .el-tabs__item {
  font-size: 20px;
  color: black;
}
// ::v-deep .el-tabs__nav {
//   height:100px;
// }
::v-deep .el-tabs__nav-wrap::after {
  background-color: transparent;
}
.bottom_body {
  background-color: #f7fafd;
  padding: 30px 80px;
  box-sizing: border-box;
  .case_item {
    background: #fff;
    border-radius: 20px;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 200px;
      margin-bottom: 20px;
    }
    .bottom_text {
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      padding: 0 15px;
    }
    .date {
      font-size: 16px;
      color: #949191;
      line-height: 30px;
      padding: 0 15px;
    }
  }
  ::v-deep .el-row {
    margin-bottom: 60px;
    flex-wrap: wrap;
  }
}
::v-deep .el-pagination {
  text-align: center;
  background-color: #f7fafd;
}
</style>
