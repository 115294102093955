<!-- star -->
<template>
  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1"  :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：<span>关于我们</span></div>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="公司介绍" name="first">
        <div class="introduce">
          <div>
            &nbsp;&nbsp;海南海泰克思技术有限公司在海南省文昌市航天现代城创立。是云计算、大数据、人工智能、机器人、智慧城市平台服务的综合提供商。海泰克思以“智慧服务社会”为使命，为政府及企业提供智能化的综合解决方案。公司深度把握政府、企业需求，自主开发惠企通企业服务平台、无感审批动态监管平台、绩效考核平台、重点项目全过程监管平台、新时代文明实践云平台。
          </div>
          <div>
            &nbsp;&nbsp;公司具备大型项目的规划、咨询、开发与实施的能力，能够在软件开发信息基础平台集成、弱电系统总包以及语音、网管、存储、安全、应用交付、无线在内的多项专业领域，以丰富实施经验，精湛的技术水平，为客户提供更安全、迅捷的服务。
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="合作伙伴" name="second">
        <div class="cooperate">
<img src="@/assets/img/model/hezuohuoban.jpg" alt="">
        </div>
      </el-tab-pane>
    </el-tabs>
    <footer2></footer2>
  </div>
</template>

<script>
import axios from 'axios'

import footer2 from '@/components/footer.vue'
import banner2 from '@/components/banner.vue'
import nav2 from '@/components/nav.vue'
export default {
  name: 'zqt',
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: '关于我们',
      Img1: require('@/assets/img/model/about.jpg'),
      activeName: 'first',
    }
  },
  methods: {
    handleClick(){}
  },
}
</script>
<style scoped lang="scss">
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}

/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}

::v-deep .el-tabs__nav-wrap {
  background-color: #f0f4f9;
  // height:100px;
}
::v-deep .el-tabs__nav-scroll {
  height: 100px;
  line-height: 100px;
  display:flex;
  justify-content: center;
  align-items:center;
}
::v-deep .el-tabs__active-bar {
  width: 40%;
}
::v-deep .el-tabs__item {
  font-size: 20px;
  color: black;
}
// ::v-deep .el-tabs__nav {
//   height:100px;
// }
::v-deep .el-tabs__nav-wrap::after {
  background-color: transparent;
}
.introduce{
  font-size:20px;
  color:#797979;
  padding:50px 150px 300px 200px;
  box-sizing: border-box;
  line-height:50px;
}
.cooperate{
  padding:50px 100px 300px 100px;
  box-sizing: border-box;
  img{
    width:100%;
  }
}
</style>
