import request from '@/utils/request'
// 提交资讯信息
export function consult(data) {
  return request({
    url: '/htks/consult',
    method: 'post',
    data,
  })
}
//意向信息
export function purpose(data) {
  return request({
    url: '/htks/purpose',
    method: 'post',
    data,
  })
}
export function infoList(data) {
  return request({
    url: '/htks/infoList',
    method: 'post',
    data,
  })
}
export function infoDetail(params) {
  return request({
    url: `/htks/infoDetail`,
    method: 'get',
    params
  })
}