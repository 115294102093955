<!-- star -->
<template>
  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：<span>客户案例</span></div>
    </div>
    <div class="case_title">{{ form.title }}</div>
    <div class="case_time">
      <span> 发布日期:{{form.publishDate}}</span> <span>发布单位:{{ form.source }}</span>
      <svg
        t="1708584381823"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2594"
        width="20"
        height="20"
      >
        <path
          d="M979.222 415.474C894.954 332.02 776.31 192.004 509.958 192.004c-266.316 0-384.962 140.016-469.23 223.47-19.86 19.682-30.788 45.784-30.788 73.534 0 27.728 10.93 53.85 30.788 73.51 84.27 83.476 202.896 223.47 469.23 223.47 266.368 0 384.996-139.994 469.264-223.47 19.86-19.66 30.79-45.784 30.79-73.51C1010.01 461.256 999.082 435.156 979.222 415.474zM940.882 524.282C862.556 601.838 756.802 732 509.958 732c-246.81 0-352.564-130.164-430.89-207.718-9.548-9.468-14.8-22.01-14.8-35.276 0-13.288 5.252-25.806 14.8-35.252 78.344-77.578 184.098-207.764 430.89-207.764 246.826 0 352.58 130.186 430.924 207.764 9.55 9.446 14.802 21.966 14.802 35.252C955.684 502.272 950.432 514.812 940.882 524.282zM509.974 335.996c-84.868 0-153.928 68.652-153.928 152.988 0 84.358 69.06 153.01 153.928 153.01 84.872 0 153.93-68.652 153.93-153.01C663.904 404.648 594.846 335.996 509.974 335.996zM509.974 588.008c-54.912 0-99.6-44.428-99.6-99.024 0-54.574 44.69-98.978 99.6-98.978 54.912 0 99.6 44.404 99.6 98.978C609.576 543.58 564.888 588.008 509.974 588.008z"
          p-id="2595"
          fill="#cccccc"
        ></path>
      </svg>
      <span> {{ form.views }}</span>
    </div>
    <div class="case_text" v-html="form.content">
    </div>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import {infoDetail}from '@/api'
import footer2 from '@/components/footer.vue'
import banner2 from '@/components/banner.vue'
import nav2 from '@/components/nav.vue'
export default {
  name: 'zqt',
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: '客户案例',
      Img1: require('@/assets/img/model/about.jpg'),
      activeName: 'first',
      form: {
        content:''
      }
    }
  },
  methods: {
    async infoDetail() {
      const res = await infoDetail({ id: this.$route.query.id })
      if (res.data.code == 200) {
        this.form = res.data.data
        console.log(this.form);
      }
      
    },
    handleClick() {},
  },
 async created () {
   await this.infoDetail()
  }
}
</script>
<style scoped lang="scss">
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}

/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}

.case_title {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}
.case_time {
  font-size: 18px;
  color: #a6a4a7;

  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 10px;
  }
}
.case_text {
  // line-height: 40px;
  padding: 30px 100px;
  box-sizing: border-box;
  // color:#333333;
}
</style>
